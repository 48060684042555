import { db } from "auth/FirebaseAuth";
import { GetRosters, DeleteRoster } from "../Handlers/Rosters";
import {
  GetUserProfile,
  AddUserToSquad,
  UpdateUserActiveSquad,
  JoinParentByPlayerCode,
  GetActiveSquadCode,
} from "../Handlers/User";
import {
  GetThreadDetails,
  ListenToMessageCollection,
  SendMessage,
} from "../Handlers/Messages";
const FirebaseDBService = {};

FirebaseDBService.GetThreadDetails = GetThreadDetails;

FirebaseDBService.ListenToMessageCollection = ListenToMessageCollection;

FirebaseDBService.SendMessage = SendMessage;

FirebaseDBService.GetUserProfile = GetUserProfile;

FirebaseDBService.UpdateUserActiveSquad = UpdateUserActiveSquad;

FirebaseDBService.GetRosters = GetRosters;

FirebaseDBService.DeleteRoster = DeleteRoster;

FirebaseDBService.VerifyInviteCode = async (code) => {
  try {
    const inviteCode = (await db.doc(`/invites/${code}`).get()).data();
    if (inviteCode) return inviteCode;
    throw new Error("Invalid Invite Code");
  } catch (err) {
    throw err;
  }
};

FirebaseDBService.AddUserToSquad = AddUserToSquad;

FirebaseDBService.JoinParentByPlayerCode = JoinParentByPlayerCode;

FirebaseDBService.GetActiveSquadCode = GetActiveSquadCode;

export default FirebaseDBService;
