import { db } from "auth/FirebaseAuth";

export const GetRosters = async (squadId) => {
  try {
    const rosters = await db
      .collection("squads")
      .doc(`${squadId}`)
      .collection("roster")
      .get();
    if (rosters.docs.length > 0) {
      const rosterData = await Promise.all(
        rosters.docs.map(async (doc) => {
          const user = await db.collection("users").doc(doc.id).get();

          return {
            rosterId: doc.id,
            displayName: user.data().displayName,
            email: user.data().email,
            phoneNumber: user.data().phoneNumber,
            photoURL: user.data().photoURL,
            role: doc.data().role,
            parent: GetRosterParent(doc.data()),
          };
        })
      );
      return rosterData;
    }
    //return rosters;
  } catch (err) {
    throw err;
  }
};

export const UpdateRoster = async (userId, user, codeData) => {
  try {
    // await db.doc(`/squads/${squadId}/roster/${userId}`).set({
    //   name: user.name,
    //   type: user.type,
    // });
    return {
      name: user.name,
      role: user.role,
    };
  } catch (err) {
    throw err;
  }
};
const DeleteRosterParent = async (batch, user, squadId) => {
  const relations = user.relationships ? user.relationships : [];
  console.log(relations.length);
  for (let i = 0; i < relations.length; i++) {
    const relation = relations[i];
    try {
      if (relation.type === "lead") {
        const rosterId = relation.userId;
        const roster = db.doc(`squads/${squadId}/roster/${rosterId}`);
        const rosterData = await roster.get();
        if (!rosterData.exists) {
          throw Error("Roster not exists");
        }
        const parentUser = db.doc(`/users/${rosterId}`);
        const parentUserData = (await parentUser.get()).data();
        const updatedUser = await RemoveUserSquads(parentUserData, squadId);

        console.log("Updated User:", updatedUser);
        const userRole = db.doc(`/userroles/${rosterId}`);
        const updatedUserRole = await GetUserUpdatedRole(userRole, squadId);
        console.log("Updated User Role:", updatedUserRole);

        batch.update(parentUser, updatedUser);
        batch.set(userRole, updatedUserRole);
        batch.delete(roster);
      }
    } catch (err) {
      console.log("Delete Roster Parent", err);
      //throw err;
    }
  }
};
export const DeleteRoster = async (squadId, rosterId) => {
  try {
    let batch = db.batch();
    const roster = db.doc(`squads/${squadId}/roster/${rosterId}`);
    const rosterData = await roster.get();
    if (!rosterData.exists) {
      throw Error("Roster not exists");
    }
    const user = db.doc(`/users/${rosterId}`);
    const userData = (await user.get()).data();
    const updatedUser = await RemoveUserSquads(userData, squadId);

    console.log("Updated User:", updatedUser);
    const userRole = db.doc(`/userroles/${rosterId}`);
    const updatedUserRole = await GetUserUpdatedRole(userRole, squadId);
    console.log("Updated User Role:", updatedUserRole);

    console.log(userData);
    batch.update(user, updatedUser);
    batch.set(userRole, updatedUserRole);
    batch.delete(roster);
    await DeleteRosterParent(batch, userData, squadId);
    await batch.commit();
    // roster.delete();
    //const returnObj = { ...rosterData.data() };
    const rosters = await GetRosters(squadId);
    return rosters;
  } catch (err) {
    throw err;
  }
};

const GetUserUpdatedRole = async (userRoleDoc, squadId) => {
  const userRoleData = (await userRoleDoc.get()).data();
  let userUpdateRole = { ...userRoleData };
  delete userUpdateRole[squadId];
  // for (const property in userRoleData) {
  //   const obj = userRoleData[property];
  //   if (obj["squad id"] !== squadId)
  //     userUpdateRole = { ...userUpdateRole, property: { ...obj } };
  // }
  return userUpdateRole;
};

const RemoveUserSquads = async (userDoc, squadId) => {
  let userSquads = userDoc.squads || [];
  userSquads = userSquads.filter((squad) => {
    return squad.id !== squadId;
  });
  const activeSquad = userSquads.length > 0 ? userSquads[0].id : null;
  return {
    squads: [...userSquads],
    recentActivity: { activeSquad: activeSquad },
  };
};

const GetRosterParent = (data) => {
  if (data.relationships) {
    let parents = "";
    for (let i = 0; i < data.relationships.length; i++) {
      if (i !== 0) {
        parents = parents + ",";
      }
      parents = parents + data.relationships[i].name;
    }
    return parents;
  }
  return "-";
};
