import React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import Views from "./views";
import { Route, Switch } from "react-router-dom";

import { getUserState } from "redux/actions/ApiRequest";

const App = (props) => {
  const { getUserState } = props;
  React.useEffect(() => {
    getUserState();
  });
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" component={Views} />
        </Switch>
      </Router>
    </div>
  );
};

const mapDispatchToProps = {
  getUserState,
};
export default connect(null, mapDispatchToProps)(App);
