import React from "react";
import { Layout, Grid } from "antd";
import { Switch, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SideNav from "components/layout-components/SideNav";
import TopNav from "components/layout-components/TopNav";
import MobileNav from "components/layout-components/MobileNav";
import HeaderNav from "components/layout-components/HeaderNav";
import PageHeader from "components/layout-components/PageHeader";
import Footer from "components/layout-components/Footer";
import AppViews from "views/app-views";
import { getNavigationConfig } from "configs/NavigationConfig";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
} from "constants/ThemeConstant";
import utils from "utils";
import Loading from "components/shared-components/Loading";

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({
  match,
  navCollapsed,
  navType,
  location,
  loading,
  user,
}) => {
  const currentRouteInfo = utils.getRouteInfo(
    getNavigationConfig(),
    location.pathname
  );

  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes("lg");
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loading cover="content"></Loading>
      ) : (
          <Layout>
            <HeaderNav isMobile={isMobile} />
            {isNavTop && !isMobile ? (
              <TopNav routeInfo={currentRouteInfo} />
            ) : null}
            <Layout className="app-container">
              {isNavSide && !isMobile ? (
                <SideNav routeInfo={currentRouteInfo} />
              ) : null}
              <Layout
                className="app-layout"
                style={{ paddingLeft: getLayoutGutter() }}
              >
                <div
                  className={`app-content ${isNavTop ? "layout-top-nav" : ""}`}
                >
                  <PageHeader
                    display={currentRouteInfo?.breadcrumb}
                    title={currentRouteInfo?.title}
                  />
                  <Content>
                    <Switch>
                      <Route path="" component={AppViews} />
                    </Switch>
                  </Content>
                </div>
                <Footer />
              </Layout>
            </Layout>
            {isMobile && <MobileNav />}
          </Layout>
        )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { navCollapsed, navType, locale } = theme;
  const { loading, user } = auth;
  return { navCollapsed, navType, locale, loading, user };
};

export default withRouter(connect(mapStateToProps)(AppLayout));
