import React from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import {
  // MenuFoldOutlined,
  // MenuUnfoldOutlined,
  // SearchOutlined,
} from "@ant-design/icons";
import Logo from "./Logo";
// import NavNotification from "./NavNotification";
import NavProfile from "./NavProfile";
// import NavLanguage from "./NavLanguage";
// import NavPanel from "./NavPanel";
//import NavSearch from "./NavSearch";
import SquadNavDropdown from "./Squad/NavDropdown";
//import SearchInput from "./NavSearch/SearchInput.js";
import { toggleCollapsedNav, onMobileNavToggle } from "redux/actions/Theme";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import utils from "utils";

const { Header } = Layout;

export const HeaderNav = (props) => {
  const {
    navCollapsed,

    navType,
    headerNavColor,
    // mobileNav,
    // toggleCollapsedNav,
    // onMobileNavToggle,
    isMobile,
  } = props;
  //const [searchActive, setSearchActive] = useState(false);

  // const onSearchActive = () => {
  //   setSearchActive(true);
  // };

  // const onSearchClose = () => {
  //   setSearchActive(false);
  // };

  // const onToggle = () => {
  //   if (!isMobile) {
  //     toggleCollapsedNav(!navCollapsed);
  //   } else {
  //     onMobileNavToggle(!mobileNav);
  //   }
  // };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = utils.getColorContrast(headerNavColor);
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };
  return (
    <Header className={`app-header`} style={{ backgroundColor: "#1890ff" }}>
      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        <Logo logoType={mode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          {/* <div className="nav-left">
            <Menu mode="horizontal">
              {isNavTop && !isMobile ? null : (
                <Menu.Item
                  key="0"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined
                      className="nav-icon"
                      style={{ color: "white" }}
                    />
                  ) : (
                      <MenuFoldOutlined
                        className="nav-icon"
                        style={{ color: "white" }}
                      />
                    )}
                </Menu.Item>
              )}
              {isMobile ? (
                <Menu.Item
                  key="1"
                  onClick={() => {
                    onSearchActive();
                  }}
                >
                  <SearchOutlined style={{ color: "white" }} />
                </Menu.Item>
              ) : (
                  <Menu.Item key="1" style={{ cursor: "auto" }}>
                    <SearchInput mode={mode} isMobile={isMobile} />
                  </Menu.Item>
                )}
            </Menu>
          </div> */}
          <div className="nav-right">
            <SquadNavDropdown></SquadNavDropdown>
            <NavProfile />
            {/* <NavNotification />
            <NavLanguage />

            <NavPanel /> */}
          </div>
          {/* <NavSearch active={searchActive} close={onSearchClose} /> */}
        </div>
      </div>
    </Header>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav } = theme;
  return { navCollapsed, navType, headerNavColor, mobileNav };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
})(HeaderNav);
