import firebase from "firebase";
import { db } from "auth/FirebaseAuth";
import { GetActiveSquadId, GetUser } from "utils/user";
import { MessageType } from "enums/enums";

export const ListenToMessageCollection = (onMessageReceived) => {
  const squadId = GetActiveSquadId();
  return db
    .collection("chats")
    .doc(squadId)
    .collection("messages")
    .onSnapshot(
      (snapshot) => {
        if (!snapshot.metadata.hasPendingWrites) {
          console.log(snapshot.docChanges().length);
          if (snapshot.docChanges().length > 0) {
            const createdDoc = snapshot.docChanges()[0].doc.data();
            onMessageReceived(createdDoc);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  //   db.document("users/{userId}").onCreate((snap, context) => {
  //     // Get an object representing the document
  //     // e.g. {'name': 'Marie', 'age': 66}
  //     const newValue = snap.data();

  //     // access a particular field as you would any JS property
  //     const name = newValue.name;

  //     // perform desired operations ...
  //   });
};

export const SendMessage = async (
  content,
  fileUrl = null,
  type = MessageType.text
) => {
  try {
    const batch = db.batch();
    const user = GetUser();
    const message = {
      type: type,
      content: content,
      fileUrl: fileUrl,
      from: user.displayName,
      fromUid: user.uid,
      photo: user.photoURL,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    //console.log("Message:", firebase.firestore.FieldValue.serverTimestamp());
    const squadId = GetActiveSquadId();
    batch.update(db.doc(`/chats/${squadId}`), {
      lastMessage: content,
      lastMessageTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
    });
    batch.set(
      db.doc(`/chats/${squadId}`).collection("messages").doc(),
      message
    );
    await batch.commit();
    //await db.doc(`/threads/${squadId}`).collection("messages").add(message);
    return message;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const GetThreadDetails = async (squadId) => {
  try {
    const squadThread = await db.doc(`/chats/${squadId}`).get();

    if (!squadThread.exists) throw Error("No thread found");

    const messages = await GetThreadMessages(squadId);

    return [{ ...squadThread.data(), id: 1, messages: messages }];
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const GetThreadMessages = async (squadId) => {
  try {
    const messages = await db
      .collection("chats")
      .doc(`${squadId}`)
      .collection("messages")
      .orderBy("timestamp", "asc")
      .get();

    if (!messages.docs.length > 0) return [];

    const messageData = messages.docs.map((message) => {
      return message.data();
    });

    return messageData;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
