import { BASE_URL } from "constants/constants";
import { RouteType } from "enums/enums";
import { GetActiveSquadId } from "./user";

export const GetBaseURL = () => {
  if (window.location.hostname === "localhost") {
    return "http://localhost:5001/squadappdev-300514/us-central1/api/";
  }
  return BASE_URL;
};

export const GetInviteCode = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const GetRouteKey = (type) => {
  switch (type) {
    case RouteType.squad:
      return GetActiveSquadId();
    default:
      return GetActiveSquadId();
  }
}
