import { db } from "auth/FirebaseAuth";
import { GetUserId } from "utils/user";
import { GetInviteCode } from "utils/utils";
import { IsAlreadyInSquad } from "../FirebaseDB/DBValidation";
import { UpdateRoster } from "./Rosters";
import { PlayerRole } from "views/app-views/squad/signup";
import { nanoid } from 'nanoid'

export const GetUserProfile = async (userId) => {
  try {
    const user = await db.doc(`/users/${userId}`).get();
    const userRoles = await db.doc(`/userroles/${userId}`).get();

    if (!user.exists) return null;
    if (userRoles.exists)
      return { ...user.data(), userroles: { ...userRoles.data() } };
    return { ...user.data(), userroles: {} };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const GetUserByCode = async (code) => {
  try {
    const user = await db.collection("users").get();
    if (user.docs.length > 0) {
      const filteredUsers = user.docs.filter((doc) => {
        if (doc.data().inviteCode) {
          return doc.data().inviteCode === code;
        }
        return false;
      });
      if (filteredUsers.length > 0) return filteredUsers[0];
      throw Error("No User Found");
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const AddParentToSquads = async (batch, user, squad) => {
  const relations = user.relationships ? user.relationships : [];
  for (let i = 0; i < relations.length; i++) {
    const relation = relations[i];
    try {
      if (relation.type === "lead") {
        const role = PlayerRole.Parent;
        const name = relation.name;
        const userId = relation.userId;

        const user = await UpdateUserSquad(userId, [squad]);
        if (!user.inviteCode) {
          user.inviteCode =
            role === PlayerRole.Player ? GetInviteCode(8) : null;
        }

        const updatedSquads = {
          inviteCode: user.inviteCode,
          squads: user.squads,
          recentActivity: user.recentActivity,
        };

        console.log(user);
        let updateRoster = await UpdateRoster(
          userId,
          { name: name, role: role },
          squad
        );

        const updateRoles = await UpdateUserRole(userId, role, [squad]);
        const squadId = squad.id;

        console.log(updateRoles);

        batch.update(db.doc(`/users/${userId}`), updatedSquads);

        updateRoster = {
          ...updateRoster,
          relationships: [...(user.relationships || [])],
        };
        batch.set(db.doc(`/squads/${squadId}/roster/${userId}`), updateRoster);
        batch.set(db.doc(`/userroles/${userId}`), updateRoles);
      }
    } catch (err) {
      console.log("Parent is already in squad");
    }
  }
};
export const AddUserToSquad = async (role, name, userId, squad) => {
  try {
    let batch = db.batch();

    const user = await UpdateUserSquad(userId, [squad]);
    if (!user.inviteCode) {
      user.inviteCode = role === PlayerRole.Player ? nanoid(8) : null;
    }

    const updatedSquads = {
      inviteCode: user.inviteCode,
      squads: user.squads,
      recentActivity: user.recentActivity,
    };

    console.log(user);
    let updateRoster = await UpdateRoster(
      userId,
      { name: name, role: role },
      squad
    );

    const updateRoles = await UpdateUserRole(userId, role, [squad]);
    const squadId = squad.id;

    console.log(updateRoles);

    batch.update(db.doc(`/users/${userId}`), updatedSquads);

    updateRoster = {
      ...updateRoster,
      relationships: [...(user.relationships || [])],
    };

    batch.set(db.doc(`/squads/${squadId}/roster/${userId}`), updateRoster);
    batch.set(db.doc(`/userroles/${userId}`), updateRoles);

    await AddParentToSquads(batch, user, squad);

    await batch.commit();

    return { ...user, userroles: { ...updateRoles } };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const JoinParentByPlayerCode = async (code, name) => {
  try {
    const userData = await GetUserByCode(code);
    const playerId = userData.id;
    let player = userData.data();
    console.log(player);
    const parentId = GetUserId();

    const squads = player.squads || [];

    let batch = db.batch();

    let parent = await UpdateUserSquad(parentId, squads);
    console.log(parent);

    let updateRoster = await UpdateRoster(parentId, {
      name: name,
      role: PlayerRole.Parent,
    });
    console.log(updateRoster);

    const updateRoles = await UpdateUserRole(
      parentId,
      PlayerRole.Parent,
      squads
    );
    console.log(updateRoles);

    player = {
      ...player,
      ...UpdateRelationship(parentId, player, parent, "lead"),
    };
    batch.set(db.doc(`users/${playerId}`), player);

    parent = {
      ...parent,
      ...UpdateRelationship(playerId, parent, player, "follow"),
    };
    batch.set(db.doc(`/users/${parentId}`), parent);

    for (let i = 0; i < squads.length; i++) {
      const squadId = squads[i].id;
      updateRoster = {
        ...updateRoster,
        relationships: [...parent.relationships],
      };
      batch.set(db.doc(`/squads/${squadId}/roster/${parentId}`), updateRoster);
    }

    for (let i = 0; i < squads.length; i++) {
      console.log("Player Squad");
      const squadId = squads[i].id;
      let playerRoster = await UpdateRoster("", {
        name: player.displayName,
        role: PlayerRole.Player,
      });

      playerRoster = {
        ...playerRoster,
        relationships: [...player.relationships],
      };
      console.log(playerRoster);
      batch.set(db.doc(`/squads/${squadId}/roster/${playerId}`), playerRoster);
    }

    batch.set(db.doc(`/userroles/${parentId}`), updateRoles);

    await batch.commit();
    return { ...parent, userroles: { ...updateRoles } };
  } catch (err) {
    throw err;
  }
};

export const UpdateUserActiveSquad = async (userId, squadId) => {
  try {
    const user = await GetUserProfile(userId);
    console.log(user);

    await db.doc(`/users/${userId}`).update({
      recentActivity: { activeSquad: squadId },
    });
    return { ...user, recentActivity: { activeSquad: squadId } }; //return full update user
  } catch (err) {
    throw err;
  }
};

const UpdateUserSquad = async (userId, squadsToJoin) => {
  try {
    const user = await GetUserProfile(userId);
    delete user.userroles;
    if (squadsToJoin.length > 0) {
      let filteredSquads = [];
      for (let i = 0; i < squadsToJoin.length; i++) {
        if (!IsAlreadyInSquad(user, squadsToJoin[i].id)) {
          filteredSquads.push(squadsToJoin[i]);
        }
      }
      if (filteredSquads.length === 0)
        throw new Error(
          "Updated User Squad: User is Already present in this squad."
        );
      const squads = [...(user.squads || []), ...filteredSquads];
      //await db.doc(`/users/${userId}`).update();
      // return {
      //   squads: squads,
      //   recentActivity: { activeSquad: codeData.id },
      // };
      return {
        ...user,
        squads,
        recentActivity: { activeSquad: filteredSquads[0].id },
      }; //return full update user
    } else {
      return {
        ...user,
      }; //return full update user
    }
  } catch (err) {
    throw err;
  }
};
export const GetActiveSquadCode = async (squadId) => {
  try {
    const squadDoc = await db
      .collection("squads")
      .doc(`${squadId}`).get();
    if (squadDoc.exists) {
      const squadData = squadDoc.data();
      return squadData.inviteCode ? squadData.inviteCode : "";
    }
    throw new Error("Squad not exist")
  } catch (err) {
    throw err;
  }
}
const UpdateUserRole = async (userId, type, squads) => {
  try {
    let obj = {};
    for (let i = 0; i < squads.length; i++) {
      const squadId = squads[i].id;
      obj[squadId] = {
        role: type,
        squadName: squads[i].name,
      };
    }
    const roles = await db.doc(`/userroles/${userId}`).get();
    if (roles.exists) {
      obj = { ...roles.data(), ...obj };
      //await db.doc(`/userroles/${userId}`).set(obj);
    }
    return obj;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const UpdateRelationship = (userId, me, other, type) => {
  if (me.relationships) {
    return {
      relationships: [
        ...me.relationships,
        { name: other.displayName, userId: userId, type: type },
      ],
    };
  }
  return {
    relationships: [{ name: other.displayName, userId: userId, type: type }],
  };
};
