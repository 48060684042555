import { GET_USER_PROFILE } from "../constants/ApiRequest";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { showLoading, signOut } from "redux/actions/Auth";
import { updateUserState } from "redux/actions/ApiRequest";
import FirebaseDBService from "services/Firebase/FirebaseDB/FirebaseDBService";
import { AUTH_TOKEN } from "../constants/Auth";
import { TOKEN } from "../../constants/constants";
import FirebaseService from "services/FirebaseService";

export function* GetUserData() {
  yield takeEvery(GET_USER_PROFILE, function* () {
    try {
      yield put(showLoading());
      const userId = localStorage.getItem(AUTH_TOKEN) || "";
      if (userId !== "") {
        const response = yield call(FirebaseDBService.GetUserProfile, userId);
        console.log(response);
        if (response) {
          const token = yield call(FirebaseService.getIdToken);
          localStorage.setItem(TOKEN, token);
          yield put(updateUserState(response));
        }
      } else {
        console.log("Sign Out as no User Id found");
        yield put(signOut());
      }
    } catch (error) {
      console.log(error);
      yield put(signOut());
      //yield put(showAuthMessage(error));
    } finally {
    }
  });
}

export default function* rootSaga() {
  yield all([fork(GetUserData)]);
}
