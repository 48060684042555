import { UPDATE_USER_STATE, GET_USER_PROFILE } from "../constants/ApiRequest";

export const updateUserState = (user) => {
  return {
    type: UPDATE_USER_STATE,
    user,
  };
};

export const getUserState = () => {
  return {
    type: GET_USER_PROFILE,
  };
};
