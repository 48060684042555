export const ContentType = Object.freeze({
  json: "application/json",
  multipart: "multipart/form-data",
});

export const MessageType = Object.freeze({
  text: "Text",
  file: "File",
});

export const RouteType = Object.freeze({
  squad: "squad",
});