import { AUTH_TOKEN } from "redux/constants/Auth";
import store from "../redux/store";

export const HasCoachRole = () => {
  const user = store.getState().auth.user;
  const { activeSquad } = user.recentActivity;
  const { userroles } = user;
  const role = userroles[activeSquad];
  if (role) {
    return role["role"] === "Coach";
  }
  return false;
};

export const GetActiveSquadId = () => {
  const user = store.getState().auth.user;
  const { activeSquad } = user.recentActivity;
  if (activeSquad) {
    return activeSquad;
  }
  return null;
};
export const GetActiveSquad = () => {
  const user = store.getState().auth.user;
  const { activeSquad } = user.recentActivity;
  if (activeSquad) {
    return user.squads.filter((squad) => {
      return squad.id === activeSquad;
    })[0];
  }
  return null;
};

export const IsMe = (id) => {
  const userId = localStorage.getItem(AUTH_TOKEN);
  return userId === id;
};

export const GetUserId = () => {
  return localStorage.getItem(AUTH_TOKEN);
};
export const GetUser = () => {
  const user = store.getState().auth.user;
  return { ...user, uid: GetUserId() };
};
