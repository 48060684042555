import React, { Fragment } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, Spin } from "antd";
import { getUserState, updateUserState } from "redux/actions/ApiRequest";
import { Card, Row, Col } from "antd";
import { LockOutlined } from "@ant-design/icons";
import FirebaseDBService from "services/Firebase/FirebaseDB/FirebaseDBService";
import { AUTH_TOKEN } from "../../../../redux/constants/Auth";

export const PlayerRole = Object.freeze({
  None: "None",
  Coach: "Coach",
  Player: "Player",
  Parent: "Parent",
});
export const SquadSignUp = ({ user, updateUserState }) => {
  const history = useHistory();

  const [getRole, setRole] = React.useState(PlayerRole.None);
  const [inviteCode, setInviteCode] = React.useState("");
  const [isLoading, showLoading] = React.useState(false);
  const [codeError, setCodeError] = React.useState("");
  const OnJoinSquadButtonClicked = async () => {
    setCodeError("");
    showLoading(true);
    switch (getRole) {
      case PlayerRole.Coach:
      case PlayerRole.Player:
        JoinPlayerCoachRequest();
        break;
      case PlayerRole.Parent:
        JoinParentRequest();
        break;
      default:
        break;
    }
  };

  const JoinParentRequest = async () => {
    try {
      const parentUser = await FirebaseDBService.JoinParentByPlayerCode(
        inviteCode,
        user.displayName
      );
      updateUserState(parentUser);
      if (parentUser.recentActivity && parentUser.recentActivity.activeSquad) {
        const squadId = parentUser.recentActivity.activeSquad;
        history.push(`/app/${squadId}/roster`);
      }
      return;
    } catch (err) {
      setCodeError(err.message);
      console.log(err);
    }
    showLoading(false);
  };
  const JoinPlayerCoachRequest = async () => {
    try {
      const codeData = await FirebaseDBService.VerifyInviteCode(inviteCode);
      const userId = localStorage.getItem(AUTH_TOKEN);
      const userData = await FirebaseDBService.AddUserToSquad(
        getRole,
        user.displayName,
        userId,
        codeData
      );

      updateUserState(userData);
      history.push(`/app/${codeData.id}/roster`);
      return;
    } catch (err) {
      setCodeError("Invalid Code. Try a new one.");
      console.log(err);
    }
    showLoading(false);
  };
  const getPanelHeader = () => {
    switch (getRole) {
      case PlayerRole.Parent:
        return (
          <Fragment>
            <div>
              <h2 className={"font-weight-bold"}>Hello Parent!</h2>
            </div>
            <div>
              <h4 className={"font-weight-bold mt-4"}>Link to a Player</h4>
            </div>
          </Fragment>
        );
      case PlayerRole.Coach:
        return (
          <Fragment>
            <div>
              <h2 className={"font-weight-bold"}>Hey Coach!</h2>
            </div>
            <div>
              <h4 className={"font-weight-bold mt-4"}>Join a Squad</h4>
            </div>
          </Fragment>
        );
      case PlayerRole.Player:
        return (
          <Fragment>
            <div>
              <h2 className={"font-weight-bold"}>What up Player!</h2>
            </div>
            <div>
              <h4 className={"font-weight-bold mt-4"}>Join a Squad</h4>
            </div>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <div>
              <h4 className={"font-weight-bold"}>Signup for Squad</h4>
            </div>
          </Fragment>
        );
    }
  };
  const inviteCodePanel = () => {
    return (
      <Form layout="vertical" name="login-form">
        <div>
          <div className="d-flex flex-column">
            <Form.Item
              label={"Invite Code"}
              validateStatus={codeError === "" ? "" : "error"}
              help={codeError}
            >
              <Input
                placeholder={"Enter a code"}
                value={inviteCode}
                onChange={(e) => {
                  setInviteCode(e.target.value);
                }}
                prefix={<LockOutlined className="text-primary" />}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size={"large"}
                block
                onClick={OnJoinSquadButtonClicked}
              >
                Join
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    );
  };
  const signUpPanel = () => {
    return (
      <Form layout="vertical" name="login-form">
        <div>
          <div className="d-flex justify-content-center">
            <Form.Item>
              <Button
                type="primary"
                size={"large"}
                block
                className="mb-3"
                onClick={() => {
                  setRole(PlayerRole.Coach);
                }}
              >
                I'm a Coach
              </Button>
              <Button
                type="primary"
                size={"large"}
                block
                className="mb-3"
                onClick={() => {
                  setRole(PlayerRole.Player);
                }}
              >
                I'm a Player
              </Button>
              <Button
                type="primary"
                size={"large"}
                block
                onClick={() => {
                  setRole(PlayerRole.Parent);
                }}
              >
                I'm a Parent
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    );
  };

  const getActivePanel = () => {
    switch (getRole) {
      case PlayerRole.None:
        return signUpPanel();
      default:
        return inviteCodePanel();
    }
  };
  return (
    <>
      <Spin spinning={isLoading}>
        <div className="h-100">
          <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center">
              <Col xs={20} sm={20} md={20} lg={7}>
                <Card>
                  <div className="my-4">
                    <div className="text-center">
                      <img
                        className="img-fluid mb-2"
                        src="/img/SQUAD_BLUE_Logo.png"
                        alt=""
                        style={{ width: "95", height: "25px" }}
                      />
                      {getPanelHeader()}
                    </div>
                    <Row justify="center">
                      <Col xs={24} sm={24} md={20} lg={20}>
                        {getActivePanel()}
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
    </>
  );
};

// LoginForm.propTypes = {
//   otherSignIn: PropTypes.bool,
//   showForgetPassword: PropTypes.bool,
//   extra: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
// };

// LoginForm.defaultProps = {
//   otherSignIn: true,
//   showForgetPassword: false,
// };

const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return { user };
};

const mapDispatchToProps = {
  getUserState,
  updateUserState,
};

export default connect(mapStateToProps, mapDispatchToProps)(SquadSignUp);
