import React, { Fragment } from "react";
import { message, Select } from "antd";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import { updateUserState } from "redux/actions/ApiRequest";
import { GetActiveSquad } from "utils/user";

const { Option } = Select;
export const NavDropdown = ({ user, updateUserState }) => {
  const history = useHistory();

  const squadsCount = user.squads.length;

  const OnSquadSelection = async (id) => {
    const key = "Squad Dropdown";
    try {
      const { activeSquad } = user.recentActivity;
      if (activeSquad === id) {
        message.info({ content: "Already Active", key, duration: 1.5 });
        return;
      }
      updateUserState({ ...user, recentActivity: { activeSquad: id } });
      history.push(`/app/${id}/roster`);
    } catch (err) {
      message.error({ content: "Error!", key, duration: 1.5 });
    }
  };

  const dropdown = (
    <Fragment>
      <div className="mb-3">
        <Select
          value={GetActiveSquad() ? GetActiveSquad().name : "Squads"}
          style={{ maxWidth: 220 }}
          placeholder="Squads"
          onChange={(value) => {
            OnSquadSelection(value);
          }}
        >
          {user.squads.map((elm, index) => (
            <Option key={index} value={elm.id}>
              {elm.name}
            </Option>
          ))}
        </Select>
      </div>
    </Fragment>
  );
  return squadsCount > 1 ? dropdown : null;
};

const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return { user };
};

export default connect(mapStateToProps, { updateUserState })(NavDropdown);
