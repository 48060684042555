// Application Constants
export const TOKEN = "AUTH_TOKEN";
export const AUTH_TOKEN_HEADER = "x-auth-token";
export const BASE_URL =
  "https://us-central1-squadappdev-300514.cloudfunctions.net/api/";

// API Constants

export const LOGIN = "login";
export const UPDATE_PROFILE = "update-profile";
export const GET_USER_PROFILE = "get-user-profile";

// Routes Constant
export const ROUTE_LOGIN = "/game-login";
export const ROUTE_GAME_RULES = "/game-rules";
export const ROUTE_START_INTRO = "/game-start-intro";
export const ROUTE_TEAM_NAME = "/game-team-name";
export const ROUTE_RESUME_INTRO = "/game-resume-intro";
export const ROUTE_GAME_STEP = "/game-play";
export const ROUTE_RESULT = "/game-result";
export const ROUTE_LEADERBOARD = "/game-leaderboard";
export const ROUTE_SQUAD_ROSTER = "/app/squad/roster";
