// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export default {
  apiKey: "AIzaSyDsuKAj433QQzLJInJDoGX5l5dLxGIOIjk",
  authDomain: "squadappdev-300514.firebaseapp.com",
  // databaseURL: "https://squadapp.firebaseio.com",
  projectId: "squadappdev-300514",
  storageBucket: "squadappdev-300514.appspot.com",
  messagingSenderId: "338186220486",
  appId: "1:338186220486:web:f95caf2cc72b59c53ef8c7",
  measurementId: "G-Q3RJZ342PK"
};
