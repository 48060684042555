import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage"
import firebaseConfig from "configs/FirebaseConfig";

firebase.initializeApp(firebaseConfig);

// firebase utils
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

if (window.location.hostname === "localhost") {
  //db.useEmulator("localhost", 4400);
  db.settings({
    host: "localhost:5002",
    ssl: false,
  });
  auth.useEmulator("http://localhost:9099/");
}
const currentUser = auth.currentUser;
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();

export {
  db,
  auth,
  storage,
  currentUser,
  googleAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  githubAuthProvider,
};
